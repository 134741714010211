import React, { useState } from 'react';
import {
  Container, Row, Col,
} from 'reactstrap';
import { Link } from 'gatsby';
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion';
import Layout from '../../components/layout';
import SEO from '../../components/seo';
import Section from '../../components/Section/Section';
import Headline from '../../components/Headline/Headline';

import imageAkkupunktur from '../../images/Akkupunktur-min.jpg';
import imageErnaehrung from '../../images/Ernaehrungsmedizin (unsplash)-min.jpg';
import imageMesotherapie from '../../images/Mesotherapie (unsplah)-min.jpg';

const LeistungenPage = () => (
  <Layout headerWhite>
    <SEO title="Therapien | Orthopädie Dr. Schubert" description="Orthopädische Therapien ✓ Mehr als 20 Jahre Berufserfahrung ✓ Akupunktur ✓ Mesotherapie ✓ Ernährungsmedizin ✓" />
    <div className="greenContainer">
      <ul>
        <li><Link to="/therapien/klassisch">Klassisch</Link></li>
        <li><Link to="/therapien/innovativ">Innovativ</Link></li>
        <li className="active"><Link to="/therapien/integrativ">Integrativ</Link></li>
      </ul>
    </div>
    <Section dark>
      <Container>
        <Headline h1 light subtitle="Klassisch / Innovativ / Integrativ">Therapien</Headline>
        <Accordion>
          <AccordionItem>
            <AccordionItemHeading>
              <AccordionItemButton>
                Ernährungsmedizin -
                {' '}
                <i>Beratung und Therapie</i>
              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
              <Row>
                <Col xs={12} sm={6}>
                  <p>
                    In unserer modernen Gesellschaft sind wir einem Überfluss an Lebensmitteln ausgesetzt. Uns fällt es zunehmend schwerer, eine richtige Auswahl zu treffen. Der menschliche Körper braucht aber Nährstoffe, um zu funktionieren. Doch welches Essen tut uns gut und hilft uns, gesund zu bleiben?
                    <br />
                    <br />
                    Hierbei stehen wir Ihnen gerne mit einer Ernährungsberatung beiseite. Zuerst erfassen wir den aktuellen Ernährungsstand (was esse ich, wie viel, wann und was möchte ich erreichen).
                    <br />
                    Eine ausführliche körperliche und apparative Untersuchung inklusive Labordiagnostik werden uns weitere Informationen geben.
                    <br />
                    <br />
                    Wir werden Ihre Ernährung neu planen und umstellen.
                    <br />
                    <br />
                    Durch die Gewichtsoptimierung können oftmals auch bei bestehenden Erkrankungen Medikamente eingespart oder überflüssig werden.
                    <br />
                    <br />
                    Unsere Beratungen erfolgen ausschließlich in Einzelbehandlungen und nicht in Gruppen. Nach dem Erstgespräch können die Folgetermine auch telefonisch oder per Videosprechstunde stattfinden.
                  </p>

                </Col>
                <Col xs={12} sm={6}><img src={imageErnaehrung} alt="Ernährungsmedizin" /></Col>

              </Row>

            </AccordionItemPanel>
          </AccordionItem>
          </Accordion>
          <Accordion>
          <AccordionItem>
            <AccordionItemHeading>
              <AccordionItemButton>

                Mesotherapie -
                {' '}
                <i>Behandlung über die Haut</i>

              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
              <p>
                Die Mesotherapie vereint therapeutische Grundsätze der Akupunktur, Neuraltherapie und Arzneimitteltherapie.
                <br />
                <br />
                Diese schonende und sanfte Behandlungstechnik bringt kleinste Mengen an Wirkstoffen möglichst nahe an den Ort der Erkrankung.
                <br />
                <br />
                Eine spezielle Wirkstoffmischung aus Medikamenten, Vitaminen, Aminosäuren und Antioxidantien wird entweder als Depot lokal auf die Haut aufgetragen oder mit winzigen Nadelstichen in die oberste Hautschicht wenige Millimeter tief eingebracht.
                <br />
                Aus diesem Hautdepot werden die Wirkstoffe nach und nach in die tieferliegenden erkrankten Strukturen transportiert. Für einen schnellen und gleichzeitig lang anhaltenden Effekt sind daher nur sehr geringe Wirkstoffmengen nötig.
                <br />
                <br />
                Anwendungsbereiche:
                <ul>
                  <li>Schmerzzustände bei orthopädischen Erkrankungen</li>
                  <li>Sehnenreizungen</li>
                  <li>Durchblutungs- und Wundheilungsstörungen</li>
                  <li>Migräne und Spannungskopfschmerz</li>
                </ul>
              </p>
            </AccordionItemPanel>
          </AccordionItem>
          </Accordion>
          <Accordion>
          <AccordionItem>
            <AccordionItemHeading>
              <AccordionItemButton>

                Mikronährstofftherapie -
                {' '}
                <i>Vitamine und Mineralstoffe</i>

              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
              <p>
                Sprechen wir von Ernährung, so meinen wir im allgemeinen Fette, Kohlenhydrate und Eiweiße- die Makronährstoffe.
                <br />
                Unser Stoffwechsel benötigt aber auch sogenannte „Vitalstoffe“- die Mikronährstoffe- wie Vitamine, Spurenelemente und Mineralstoffe, ohne die unser Organismus auf zellulärer Ebene nicht funktionieren würde.
                <br />
                <br />
                Ständiger Stress, chronische Erschöpfung, ein zu viel an Sport oder Erkrankungen wie z.B die Fibromyalgie können zu Fehlfunktionen führen. Hier ist der Einsatz von Mikronährstoffen sinnvoll und zeigt gute Behandlungserfolge.
                <br />
                Wir setzen unsere  Aufbauinfusionen einmal wöchentlich über einen Zeitraum von 4 Wochen ein.
              </p>
            </AccordionItemPanel>
          </AccordionItem>
          </Accordion>
          <Accordion>
          <AccordionItem>
            <AccordionItemHeading>
              <AccordionItemButton>

                Akupunktur -
                {' '}
                <i>Harmonie durch Nadeln</i>

              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
              <Row>
                <Col xs={12} sm={6}>
                  <p>
                    Die Akupunktur ist eine der ältesten Therapieformen der Welt und gehört zur traditionellen chinesischen Medizin.
                    <br />
                    Durch Einstiche an bestimmten Punkten auf Leitungsbahnen (Meridianen), die über den Körper ziehen, wird Einfluss auf die Lebensenergie (Qi) genommen. Laut chinesischer Medizin entsteht  Krankheit durch gestörten Energiefluss. Durch die Akupunktur soll der aus dem Takt geratene Energiestrom behutsam reguliert werden.
                    <br />
                    <br />
                    Nach modernen wissenschaftlichen Untersuchungen werden durch die Akupunktur Zonen im Rückenmark und Gehirn stimuliert, die die Schmerzverarbeitung beeinflussen und zur Ausschüttung von stimmungsaufhellenden Substanzen („Glückshormone“) führen.
                    <br />
                    <br />
                    Die Wirkung der Akupunktur ist in Abhängigkeit der Punktauswahl
                    <ul>
                      <li>schmerzlindernd</li>
                      <li>abschwellend</li>
                      <li>psychisch ausgleichend</li>
                      <li>immunmodulierend</li>
                      <li>durchblutungsfördernd</li>
                    </ul>
                    <br />
                    <br />
                    Die Anwendungsgebiete reichen von chronischen Schmerzen bei verschleißbedingten Erkrankungen (Arthrose) bis hin zur Prophylaxe bei Migräne und Raucherentwöhnung.
                    <br />
                    <br />
                    Bei chronischen Kniegelenks- und Rückenbeschwerden ist die Akupunktur seit 2007 eine Leistung, deren Kosten die gesetzliche Krankenversicherung übernimmt.
                    <br />
                    <br />
                    Wir bieten neben der klassischen Körperakupunktur auch die Schädelakupunktur nach Yamamoto und Auriculotherapie nach Nogier an.
                    <br />
                    Sprechen Sie uns bitte an.
                  </p>
                </Col>
                <Col xs={12} sm={6}><img src={imageAkkupunktur} alt="Akupunktur" /></Col>

              </Row>
            </AccordionItemPanel>
          </AccordionItem>

        </Accordion>
      </Container>
    </Section>
  </Layout>
);

export default LeistungenPage;
